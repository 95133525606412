import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import Banner from "@components/banners/Ai";
import MonographArea from "@containers/monograph-area"

const MonographPage = ({pageContext, location }) => (
  <Layout location={location}>
    <Seo title="Publications of Monograph" />
    <Header />
    { <Banner
			pageContext={pageContext}
			location={location}
			title="The Publications of Monograph"
		/> }
    <main className="site-wrapper-reveal">
      <MonographArea />
    </main>
    <Footer />
  </Layout>
)

export default MonographPage
